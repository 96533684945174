import { Close } from "@material-ui/icons";
import { useEffect, useState } from "react";
import MapKecil from "../mapKecil/MapKecil";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "./locations.css";
import { LinearProgress } from "@material-ui/core";

function Locations({ detailObject, agendas, isLoading }) {
  const AutoplaySlider = withAutoplay(AwesomeSlider);

  const [images, setImages] = useState([]);
  const [isLightBox, setIsLightBox] = useState(null);
  // const [random, setRandom] = useState(0);

  // console.log(random, "random");

  useEffect(() => {
    const imgArray = agendas.filter((img) => img.foto_aktivitas[0]);
    // const randomImg = Math.floor(Math.random() * 3);

    setImages(imgArray);
  }, [agendas]);

  return (
    <div className="locations">
      <div className="location-header">
        <h2>{detailObject ? "LOKASI" : "AKTIVITAS"}</h2>
      </div>
      {detailObject ? (
        <MapKecil detailObject={detailObject} />
      ) : (
        <div className="location-images">
          {isLoading ? (
            <LinearProgress />
          ) : (
            <AutoplaySlider
              play={true}
              cancelOnInteraction={true}
              interval={6000}
              bullets={false}
              organicArrows={false} // panah
              fillParent={true} // style ikut parent
            >
              {images.map((image) => (
                <div
                  className="imageContainer"
                  key={image.id_agenda}
                  style={{ background: "linear-gradient(#1d3649, #2d3e49)" }}
                >
                  <img
                    src={image.foto_aktivitas[0].link_foto_agenda}
                    alt={image.foto_aktivitas[0].alt_foto_agenda}
                    onClick={() => setIsLightBox(image)}
                  />
                </div>
              ))}
            </AutoplaySlider>
          )}
        </div>
      )}
      {isLightBox && (
        <div className="lightbox-wrapper">
          <div className="iconClose" onClick={() => setIsLightBox(false)}>
            <Close />
          </div>
          <img
            src={isLightBox.foto_aktivitas[0].link_foto_agenda}
            alt={isLightBox.foto_aktivitas[0].alt_foto_agenda}
          />
          <h3>{isLightBox.foto_aktivitas[0].alt_foto_agenda}</h3>
        </div>
      )}
    </div>
  );
}

export default Locations;
